import React from 'react';
import * as R from 'ramda';
import Data from './data.json';

const isAfterDate = (date) => R.pipe(R.prop('airstamp'), (airstamp) => (new Date(airstamp)) > date);

const splitEpisodes = (allEpisodes) => {
	const [futureEpisodes, pastEpisodes ] = R.partition(isAfterDate(new Date()), allEpisodes);
	console.log(futureEpisodes)
	return {
		futureEpisodes,
		pastEpisodes: pastEpisodes.reverse()
	};
};

const getTimeDifference = (date, currentDate) => {
	const timeDifference = currentDate - date;
	const isLeft = timeDifference < 0 ? true : false;
	const difference = Math.abs(timeDifference);
	const days = Math.floor(difference / 1000 / 3600 / 24);
	const hours = Math.floor(difference / 1000 / 3600 - days * 24);

	let message = '';
	message += days ? `${days} days,` : '';
	message += ` ${hours} hours`;
	message += isLeft ? ' left' : ' ago';
	return message;
};

const colors = {
	darkPrimary: '#512DA8',
	primary: '#673AB7',
	lightPrimary: '#D1C4E9',
	textPrimary: '#FFFFFF',
	accent: '#4CAF50',
	secondary: '#212121',
	lightSecondary: '#353535',
	divider: '#BDBDBD'
};

const style = {
	page: {
		height: '100%',
		width: '100%',
		backgroundColor: colors.lightSecondary,
		textAlign: 'center'
	},
	episodeCard: {
		width: '350px',
		height: '150px',
		textAlign: 'center',
		margin: '1em',
		display: 'inline-block',
		backgroundColor: colors.secondary,
		padding: '15px'
	},
	showName: {
		fontWeight: 'bold',
		fontSize: '28px',
		color: '#BDBDBD',
		margin: '10px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	cardText: {
		fontSize: '18px',
		color: '#BDBDBD',
		margin: '5px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	categoryHeader: {
		textAlign: 'center',
		fontSize: '30px',
		color: 'white'
	}
};

const handleClick = (name, season, episode) => () => {
	const seasonValue = season >= 10 ? season : `0${season}`;
	const episodeValue = episode >= 10 ? episode : `0${episode}`;
	navigator.clipboard.writeText(`${name} s${seasonValue}e${episodeValue}`);
};

const renderEpisode = (episode) => {
	return (
		<div
			key={`${episode.name}_${episode.airstamp}`}
			onClick={handleClick(episode.showName, episode.season, episode.number)}
			style={style.episodeCard}
		>
			<div style={style.showName}>{episode.showName}</div>
			<div style={style.cardText}>{episode.name}</div>
			<div style={style.cardText}>
				Season {episode.season} - Episode {episode.number}
			</div>
			<div style={style.cardText}>{getTimeDifference(new Date(episode.airstamp), new Date())}</div>
		</div>
	);
};

export default class Schedule extends React.Component {
	render() {
		const { allEpisodes, timestamp } = Data;
		const { futureEpisodes, pastEpisodes } = splitEpisodes(allEpisodes);
		const currentDate = new Date(timestamp);
		return (
			<div style={style.page}>
				<style
					dangerouslySetInnerHTML={{
						__html: `
              html, body {
                margin: 0;
              }
            `
					}}
				/>
				<div style={style.categoryHeader}>Coming soon:</div>
				<div>{futureEpisodes.map(renderEpisode)}</div>
				<div style={style.categoryHeader}>Past episodes:</div>
				<div>{pastEpisodes.map(renderEpisode)}</div>
				<div style={{ color: 'white' }}>{currentDate.toString()}</div>
			</div>
		);
	}
}
